import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: () => import('../views/login.vue'),
 
}, {
  path: '/home',
  // name:'Home',
  component: Home,
  children: [{
    path: '',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: {
      pos: 0,
      keepAlive: true,
      requireAuth:true
    }
  }, { //案例详情
    path: '/details/:id',
    name: 'Details',
    component: () => import('../views/index/details.vue'),
    meta: {
      pos: 0,
    }
  }, {
    path: '/share',
    name: 'Share',
    component: () => import('../views/index/share.vue'),
    meta: {
      pos: 1,
      keepAlive: true,
      requireAuth:true
    }
  }, { //share详情
    path: '/sharedetails/:id',
    name: 'shareDetails',
    component: () => import('../views/index/sharedetails.vue'),
    meta: {
      pos: 1,
    }
  }, {
    path: '/info',
    name: 'Info',
    component: () => import('../views/index/info.vue'),
    meta: {
      pos: 2,
      keepAlive: true,
      requireAuth:true
    }
  }
  ]
}]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  /*  scrollBehavior(){
     return {x:0,y:0}
   } */
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


export default router