import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    yhgislogin:null,
    yhgisflag:null,
    yhgpath:null
  },
  mutations: {
    changeLogin(state, params) {
      state.yhgislogin = params
    },
    changeFlag(state, params) {
      state.yhgisflag = params
    },
    changePath(state, params) {
      state.yhgpath = params
    },
  },
  actions: {
  },
  modules: {
  }
})
