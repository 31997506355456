export default {
    baseURL: 'https://www.youhonggang.cn',
    api: [

        // API内容接口

        {
            name: 'getIndex',
            url: '/pc/index',
            method: 'get'
        },
        /**
         * 根据分类获取图片内容
         *  */
        {
            name: 'getList',
            url: '/pc/ajax_get_anli',
            method: 'get'
        },
        /**
         * 图片详情
         *  */
        {
            name: 'getDetail',
            url: '/pc/anli_detail',
            method: 'get'
        },
        /**
         * 验证密码
         *  */
        {
            name: 'checkPwd',
            url: '/pc/check',
            method: 'post',
            type:'multipart/form-data'
        },
        /**
         * share文章列表
         *  */
        {
            name: 'getShare',
            url: '/pc/ajax_get_share',
            method: 'get'
        },
        /**
         * share文章详情
         *  */
        {
            name: 'shareDetail',
            url: '/pc/share_detail',
            method: 'get'
        },
        /**
         * info
         *  */
        {
            name: 'info',
            url: '/pc/info',
            method: 'get'
        },

    ]
}