import axios from 'axios'
import api from './api.js'
import store from '@/store/index';
import router from '@/router/index'

import vue from 'vue'

if (process.env.NODE_ENV == 'development') {
	axios.defaults.baseURL = api.baseURL;
}
else if (process.env.NODE_ENV == 'debug') {
	axios.defaults.baseURL = api.baseURL;
}
else if (process.env.NODE_ENV == 'production') {
	axios.defaults.baseURL = api.baseURL;
}
// 请求超时时间
axios.defaults.timeout = 3600 * 1000;

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';


// 请求拦截器
axios.interceptors.request.use(
	config => {
		const token = store.state.token || localStorage.getItem('token');
		token && (config.headers.token = token);
		return config;
	},
	error => {
		return Promise.error(error);
	}
)
// 请求拦截器
axios.interceptors.request.use(
	config => {
		const token = store.state.token || localStorage.getItem('token');
		token && (config.headers.token = token);
		return config;
	},
	error => {
		return Promise.error(error);
	}
)
// 响应拦截器
axios.interceptors.response.use(
	response => {
		// 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
		// 否则的话抛出错误
		if (response.status === 200) {
			let code = response.data.code;

			switch (code) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。                
				case 401:
					break;

				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面                
				case 403:
					break;

				// 404请求不存在
				case 404:
					break;
				// 其他错误，直接抛出错误提示
				default:

			}
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	// 服务器状态码不是2开头的的情况
	// 这里可以跟你们的后台开发人员协商好统一的错误状态码    
	// 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
	// 下面列举几个常见的操作，其他需求可自行扩展
	error => {
		if (error.response.status) {
			switch (error.response.status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。                
				case 401:
					break;

				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面                
				case 403:
					break;

				// 404请求不存在
				case 404:
					break;
				// 其他错误，直接抛出错误提示
				default:

			}
			return Promise.reject(error.response);
		}
	}
);



function parseUrl(params) {
	let str = '';
	for (let key in params) {
		str += key + '=' + params[key] + '&';
	}
	if (str.length > 0) {
		str = '?' + str.substring(0, str.length - 1);
	}
	return str;
}
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: params
		}).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data)
		})
	})
}
export function post(url, params, type) {

	return new Promise((resolve, reject) => {
		if (type) {
			axios({
				url: url + parseUrl(params),
				method: 'post',
				headers: {
					'Content-Type': type
				}
			}).then(res => {
				resolve(res.data);
			}).catch(err => {
				reject(err.data);
			})

		} else {
			axios.post(url, params)
				.then(res => {
					resolve(res.data);
				}).catch(err => {
					reject(err.data);
				})
		}
	});
}
