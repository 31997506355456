import api from './api.js'
import {get,post} from './http.js'

let request = {};
api.api.map((item) => {
    let name = item.name;
    let url = item.url;
    let method = item.method;
    let type = item.type;
    
    request[name] = (params) => {
        switch(method){
            case 'post': {
                if(type){
                    return post(url,params,type);                    
                }else{
                    return post(url,params);
                }
                break;
            }
            // 默认是get
            default: {
                return get(url,params);
            }
        }
    }
});
export default request
