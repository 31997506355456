<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'Home',
  data () {
    return {
      flag: 0,
      pwd: '',
    }
  },
  methods: {
    // axio
    getIndex () {
      let that = this;

      let data = {

      };
      that.$http
        .getIndex(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log('app',res);
            let result = res.data
            that.flag = result.index_setting.flag //不加密值为1 加密值为2
            that.pwd = result.index_setting.pwd
            if (that.flag == 1) {
              localStorage.setItem('yhgflag', 1)
            } else {
              localStorage.setItem('yhgflag', 2)
            }
            let localpwd = window.localStorage.getItem('yhgloginpwd')
            // 如果密码更新
            if (localpwd !== that.pwd) {
              window.localStorage.setItem('yhgislogin', 0)
            }
            // that.$store.commit('changeFlag', that.flag);
          }
        })
    },
  },
  watch: {
    flag (newval, oldval) {
      // console.log(oldval,newval);
      if (localStorage.getItem('yhgflag') == null) {
        if (newval == 1) {

        } else if (newval == 2) {
          if (this.$route.path == '/') {

          } else {
            this.$router.push('/')
          }
        }
      }

    },
    // 如果路由发生变化，再次执行该方法
    $route (to, from) {
      this.$store.commit('changePath', to.name);
    }
  },
  created () {
    this.getIndex()
  }


}
</script>
<style lang="scss">
</style>
