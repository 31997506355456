<template>
  <div id="goTop">
    <div class="g-top" @click="goTop">
      <img src="@/assets/images/new/top.png" alt="" />
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: "mmGoTop",
  data () {
    return {
      scrollTop: "",
      goTopShow: false
    };
  },
 /*  watch: {
    scrollTop (val) {
      if (this.scrollTop > 400) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    }
  }, */
  methods: {
    /* handleScroll () {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 400) {
        this.goTopShow = true;
      }
    }, */
    goTop () {
      /* let timer = null,
        _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn () {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 150;
          document.body.scrollTop = document.documentElement.scrollTop =
            _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      }); */
      $("body,html").animate({
        scrollTop: 0
      },
        500);
    }
  },
  /* mounted () {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll);
  } */
};
</script>

<style scoped>
.g-top {
  display: none;
  float: right;
}
#goTop {
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .g-top {
    /*  position: absolute;
    bottom: 10.5%;
    right: 20px;
    z-index: 19; */
    display: block;
    padding: 60px 20px 40px 0;
    overflow: hidden;
  }
  .g-top img {
    display: block;
    width: 44px;
    float: right;
  }
}
</style>