import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import $ from 'jquery'
import '@/assets/css/style.css'
// import '@/assets/js/lib.js'


import "aos/dist/aos.css";



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
/* import Clipboard from 'clipboard';
Vue.prototype.Clipboard = Clipboard */




import vueLazyload from 'vue-lazyload'
Vue.use(vueLazyload, {
  error: require('@/assets/images/error.jpg'),
})

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

//http请求封装
import $http from '@/request/request'
Vue.prototype.$http = $http;

import api from '@/request/api'

// 公用imageUrl
// Vue.prototype.imageUrl = api.baseURL + '/jee-cms/sys/common/static/';

// import islogin from '@/utils/islogin'

// 路由守卫： 判断router/index.js中是否配置 requireAuth
router.beforeEach((to, from, next) => {
  // 判断页面是否需要检测登录状态
  let flag = localStorage.getItem('yhgflag')
  let flagLogin = localStorage.getItem('yhgislogin')
  if ( flag == 2) { //是否需要密码
    if (to.meta.requireAuth) {
      if (flagLogin==0||flagLogin==null) {  //是否已登录
        next({
          path: "/",
        })
      } else {
        next();
      }

    } else {
      next();
    }
  } else {
    if (to.path == "/") {
      next({
        path: "/home",
      });
    } else {
      next()
    }
  }

  // next()

})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
