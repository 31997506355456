<template>
  <div
    class="home"
    :class="[isOpen ? 'open' : '', model ? 'dark' : '']"
    v-if="isOut"
  >
    <!--  <div class="spinner" :class="[isOut ? 'out' : '']">
            <div class="inner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div> -->
    <div class="nav_box">
      <div class="container">
        <div class="top" :class="[fix ? 'fixed' : '']" ref="head">
          <h1 @click="tohome">
            <img src="@/assets/images/new/YHG.png" alt="" />
          </h1>
          <!-- @click="isOpen = !isOpen" -->
          <span
            class="menuBtn ue"
            @click="handleClick1"
            :class="[isOpen1 ? 'open' : '']"
          >
            <span class="icons black">
              <img src="@/assets/images/new/mk1.png" alt="" />
              <img src="@/assets/images/new/mk.png" alt="" />
            </span>
            <span class="icons white">
              <img src="@/assets/images/new/mk1-w.png" alt="" />
              <img src="@/assets/images/new/mk-w.png" alt="" />
            </span>
          </span>
          <!-- :class="[isOpen ? 'open' : '']" -->
          <span class="menuBtn" :class="{ fixed: isFixed }" @click="handleClick"
            ><b></b><b></b><b></b
          ></span>
        </div>
        <div class="box" :class="[isOpen1 ? 'open' : '']">
          <ul class="nav">
            <li @click="navliClick" :class="$route.meta.pos == 0 ? 'on' : ''">
              <router-link class="a1" to="/home">Work</router-link>
            </li>
            <li @click="navliClick" :class="$route.meta.pos == 1 ? 'on' : ''">
              <router-link class="a1" to="/share">Share</router-link>
            </li>
            <li @click="navliClick" :class="$route.meta.pos == 2 ? 'on' : ''">
              <router-link class="a1" to="/info">Info</router-link>
            </li>
          </ul>
          <div class="h-info">
            <p>
              <i class="icon"><img src="@/assets/images/new/wx.png" alt="" /></i
              ><span>WeChat：</span>{{ wechat }}
            </p>
            <p>
              <i class="icon"><img src="@/assets/images/new/yx.png" alt="" /></i
              ><span>Email: </span>{{ email }}
            </p>
            <p>
              <i class="icon"><img src="@/assets/images/new/qq.png" alt="" /></i
              ><span>QQ：</span>{{ qq }}
            </p>
          </div>

          <div class="f-copy">
            <!-- 暗黑模式切换 -->
            <div class="model_ck" @click="modelChange">
              <div class="icons">
                <div class="white">
                  <img src="@/assets/images/new/w-1.png" alt="" />
                  <img src="@/assets/images/new/w-2.png" alt="" />
                </div>
                <div class="black">
                  <img src="@/assets/images/new/b-1.png" alt="" />
                  <img src="@/assets/images/new/b-2.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 暗黑模式切换end -->
            Copyright © 2023 HongGang You
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <backtop></backtop>

    <div class="f-copy">Copyright © 2023 HongGang You</div>
  </div>
</template>

<script>
import $ from "jquery";
import backtop from '@/components/backtop';

export default {
  name: 'Home',
  components: {
    backtop
  },
  data () {
    return {
      isOut: false,
      flag: true,
      isOpen: false,
      isOpen1: false,
      fix: false,


      model: false, //暗黑模式切换

      flag: 1,
      pwd: '',
      qq: '',
      email: '',
      wechat: '',

      headH: 0,
      screenWidth: document.documentElement.clientWidth,//屏幕宽度
      scrH: 0,

      isFixed: false,




    }
  },
  created () {
    let that = this

    that.getIndex();
  },

  mounted () {
    let that = this;
    that.screenWidth = document.body.offsetWidth;
    that.scrH = $(document).scrollTop();

    window.addEventListener('scroll', this.handleScroll)
  },
  /*  watch: {
     screenWidth: function (val) {
       // console.log(val)
       this.screenWidth = val
     },
     scrH: function (val) {
       // console.log(val)
       this.scrH = val
     }
   }, */
  methods: {
    tohome () {
      this.$router.push('/home')
    },
    modelChange () {
      this.model = !this.model
      // localStorage.setItem('yhgmodel', this.model);
      if (this.model) {
        document.body.style.backgroundColor = '#131313';
      } else {
        document.body.style.backgroundColor = '#ffffff';
      }
    },
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 15) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    },

    toguid (id) {
      localStorage.setItem('toId', id);
    },



    // 导航栏状态切换
    handleClick () {
      let that = this
      that.isOpen = !that.isOpen
      // localStorage.setItem('yhgisOpen', that.isOpen);
    },
    // 移动端导航栏状态切换
    handleClick1 () {
      let that = this
      that.isOpen1 = !that.isOpen1
      if (that.isOpen1) {
        document.body.style.overflow = 'hidden';
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
        document.getElementById('app').style.overflow = '';
      }
    },
    // 移动端收起导航
    navliClick () {
      let that = this
      that.isOpen1 = false;
      document.body.style.overflow = '';
      document.getElementById('app').style.overflow = '';
    },
    // axio
    getIndex () {
      let that = this;

      let data = {

      };
      that.$http
        .getIndex(data)
        .then((res) => {
          if (res.ret == 0) {
            let result = res.data
            that.flag = result.index_setting.flag //不加密值为1 加密值为2
            that.pwd = result.index_setting.pwd
            that.qq = result.qq
            that.email = result.email
            that.wechat = result.wechat


            let flagLogin = localStorage.getItem('yhgislogin')
            if (that.flag == 2) {
              if (flagLogin==0 || flagLogin==null) {
                /* let path = that.$store.state.yhgpath //是否可以查看详情
                if (path == 'shareDetails' || path == 'Details') {
                  that.isOut = true
                }else{
                  that.$router.push('/')
                } */
                that.$router.push('/')

              } else {
                that.isOut = true
              }
            } else if (that.flag == 1) {
              that.isOut = true
            }
          }
        })
    },

  },

  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll);
  },

}
</script>
